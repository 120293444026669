import React from "react";
import { Link } from "react-router-dom";
import NavbarCommonBack from "../dashboard/NavbarcommonBack";
import {
  locdelivery,
  money,
  watch,
  rightarrow,
  userimg,
  exdelivery,
  mailingship3,
} from "../../../utilities/images";

const Shippingstock = () => {
  return (
    <>
      <NavbarCommonBack />
      <main>
        <div className="MainContentpage">
          <div className="row">
            <div className="col-md-6">
              <div className="orderReview">
                <div className="ReviewHead">
                  <h3>Ready to ship</h3>
                </div>
                <div className="table-responsive">
                  <table className="tabel-order readyToShipping">
                    <tr>
                      <div className="orderDetail_ active">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="orderDetail_ ">
                        <td>
                          <div className="userDetail">
                            <img src={userimg} />
                            <div className="userContent">
                              <h3>Rebecca R. Russell</h3>
                              <h6>France</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="userDetail">
                            <img src={mailingship3} />
                            <div className="userContent">
                              <h3 class="blueText_">Express Delivery</h3>
                              <h6>Immediately</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="orderExpress">
                <div className="ReviewHead">
                  <h3>Order#WXCOO1256</h3>
                  <span>August 30, 2022</span>
                </div>
                <div className="orderDetails_">
                  <div className="userDetail">
                    <img src={userimg} />
                    <div className="userContent">
                      <h3>Rebecca R. Russell</h3>
                      <h6>1480 Bassel Street, New Orleans, LA 70113</h6>
                    </div>
                  </div>
                  <div className="userDetail">
                    <img src={exdelivery} />
                    <div className="userContent">
                      <h3 class="blueText_">Priority Shipping</h3>
                      <h6>1-3 days</h6>
                    </div>
                  </div>
                </div>
                <div className="listOrders">
                  <table className="table table-responsive listItemsContainer">
                    <tbody>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>JFR Maduro</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>382.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Ashton Magnum</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>236.50
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Marlboro Touch</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>43.99
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
               <div className="Outstock">
                    <h5>Note</h5>
                    <h6>Out of stock</h6>
               </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Shippingstock;
