import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { loadingImg, Searchlight, uploadImgs, userImg } from '../../../utilities/images'

const PaidAmountModal = (props) => {
    return (
    <div className='totalAmount'>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <h2 className='subheading2'>Change Due: $3.40</h2>
                </div>
            </div>
            
    </div>
  )
}

export default PaidAmountModal