import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Authenticator = (props) => {
  const history = useHistory();
  const authenticateUser = (isAuthenticationRequired) => {
    if(props.auth && props.auth.isLoggedIn && props.auth.token){
      // If user are logedin then we can perform any action here

    }
    else if(isAuthenticationRequired){
      // If user are not login then redirect him to the login page
      // history.push('/');
    }
  }

  useEffect(() => {
    authenticateUser(props.isAuthenticationRequired);
  }, []);

  return (
    <>
      {props.children}
    </>
  )

}


export default Authenticator;