import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PinField from "react-pin-field"
import CustomModal from "../../components/shared/CustomModal";
import ConfirmLoginModal from './ConfirmLoginModal';

const SetPasscode = () => {
    const history = useHistory();

    const [otp, setOtp] = useState("");
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const onComplete = (code) => {
        setOtp(code);
    }

    const handleOnSubmit = () => {
        if (!otp) {
            toast.error("Please enter OTP");
            return;
        }
        else if (otp.length < 4) {
            toast.error("Please enter a valid OTP");
            return;
        }
        setModalDetail({ show: true, flag: 'confirmloginmodal', });
        setKey(Math.random());
    }

    useEffect(() => {
        // 
    }, []);
    return (
        <div className="digit-group">
                <h2 className="innerHeading">Enter Passcode</h2>
            <div className="input-groups">
            <PinField className='input_digits_'
                    length={4}
                    validate={/^[0-9]$/}
                    format={key => key}
                    // onChange ={() => {}} 
                    onComplete={(code) => onComplete(code)}
                /><br/>
                    <button type="submit" className="submitBtn mt-4 mb-4" onClick={() => { handleOnSubmit() }}>Submit</button>
                </div>

             <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                ids="confirmation"
                isRightSideModal={false}
                mediumWidth={false}
                child={modalDetail.flag === 'confirmloginmodal'
                    ? <ConfirmLoginModal closeModal={() => handleOnCloseModal()} />
                    : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default SetPasscode