import React from 'react'
import { Verified } from '../../../utilities/images'

const VerifyPhoneModal = () => {
    return (
        <div className="modal-content">
            <div className="modal-body text-center">
                <div className="verifiedPopupContainer">
                    <img src={Verified} className="img-fluid popupRelateImg" alt="" />
                    <div className="contentContainer_">
                        <h5 className="contentHeading_">Successfully verified</h5>
                        <p className="contentSubtext_">
                            Please login back to your email inbox and set your new password
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyPhoneModal