import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { addNotes, cardIcon, cashIcon, checkModal, discount, Expandrightdoublelight, jobrIcon, Robusto } from '../../../utilities/images'
import CustomModal from "../../components/shared/CustomModal";
import TotalAmount from './TotalAmountModal';
import CashModal from './CashModal';
import CheckoutModal from './CheckoutModal';
import WalletModal from './WalletModal';


const RightSidebar = (props) => {
    const history = useHistory()
    const [key, setKey] = useState(Math.random());
    const [active, setActive] = useState("");
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const [discountType, setDiscountType] = useState('percentage-discount');

    const changeDiscountType = (type) => {
        setDiscountType(type)
    }


    const closeNav2 = () => {
        console.log("Hello")
            document.getElementById("mainSidebarOne").setAttribute("style", "width:0");
            document.getElementById("moreAction").setAttribute("style", "width:0");
            document.getElementById("scrollHide").setAttribute("style", "overflow:auto")
        }
        const openActions = (id) => {
            document.getElementById(id).setAttribute("style","width:32%");
        }

        const closeActions = (id) => {
            document.getElementById(id).setAttribute("style", "width:0");
        }
        const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    return (
        <div id="mainSidebarOne" className="itemsBar">
            <div className="MenuMain">
                <div className="backBtn" onClick={() => closeNav2()}>
                    <img src={Expandrightdoublelight} alt="light-svg" />
                </div>
                <div className={props?.showSidebar == true ? "sidebar_header" : "sidebar_header"}>
                    <Link to="#" className="Numeric_text">123</Link>
                    <Link to="#" className="clear_cartBox">Clear cart</Link>
                    <Link to="#" className="more_Action" onClick={() => openActions('moreAction')}>More action</Link>
                </div>
            </div>
            <div className="sideBar_body">
                <div className="sidebarbody_icon">
                    <img src={Robusto} alt="arrow" />
                    <div className="productsDetails">
                        <h6>JFR Maduro</h6>
                        <p className='box_'>Box</p>
                        <div className='d-flex justify-content-between'>
                            <p className="productlimit"><small>x</small><b>1</b></p>
                            <div className="sidebar_Links total_count">
                                <Link to="#" className="price_total">$382.75</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebarbody_icon">
                    <img src={Robusto} alt="arrow" />
                    <div className="productsDetails">
                        <div className='d-flex justify-content-between'>
                            <h6>Marlboro Touch</h6>
                            <Link to="#" className='bundleBtn'
                                                            onClick={() => {
                                                                setModalDetail({ show: true, flag: 'bundleModal', });
                                                                setKey(Math.random());
                                                            }}
                                    >Bundle</Link>
                        </div>
                        <p className='box_'>Pack</p>
                        <div className='d-flex justify-content-between'>
                            <p className="productlimit"><small>x</small><b>1</b></p>
                            <div className="sidebar_Links total_count">
                                <Link to="#" className="price_total">$43.99</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebarbody_icon">
                    <img src={Robusto} alt="arrow" />
                    <div className="productsDetails">
                        <h6>JFR Maduro</h6>
                        <p className='box_'>Box</p>
                        <div className='d-flex justify-content-between'>
                            <p className="productlimit"><small>x</small><b>1</b></p>
                            <div className="sidebar_Links total_count">
                                <Link to="#" className='updatePriceBtn'
                                    onClick={() => { setModalDetail({ show: true, flag: 'updatePriceModal', }); setKey(Math.random()); }}>Update Price</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sidebarFooter">
                <p className="total_price">
                    <strong>Sub Total</strong>
                    <small className="total_amount"><sup>$</sup>4.00</small>
                </p>
                <p className="total_price">
                    <small>Sub Total</small>
                    <small className="total_amount"><sup>-$</sup>2.00</small>
                </p>
                <p className="total_price">
                    <small>Sub Total</small>
                    <small className="total_amount"><sup>$</sup>4.00</small>
                </p>
                <p className="total_price">
                    <strong className="total_amount">Total</strong>
                    <strong className="total_amount"><sup>$</sup>254.60</strong>
                </p>
                <p className="total_price">
                    <span className="total_amount">4
                        <small>Items</small></span>
                </p>
                <button className="primary_btn mt-4 mb-4" type="button" onClick={() => openActions('checkoutSidebar')}>Checkout <i className="las la-arrow-right ms-2 rightArrow"></i></button>
            </div>

            {/* Start moreAction Div*/}
                <div id='moreAction' className='moreAction'>
                    <div className='moreActionInner'>
                    <header className='actionHeader'>
                        <h6>More Action</h6>
                        <button className='closeAction' onClick={() => closeActions('moreAction')}>
                            <i class="las la-times"></i>
                        </button>
                    </header>
                    <ul className='actionsMenus'>
                        <li className='actionItems'>
                            <Link to='#' className='actionLinks' onClick={() => openActions('discountAction')}>
                                <img src={discount} alt='' className='img-fluid'></img>
                                <span>Add Discount</span>
                            </Link>
                        </li>
                        <li className='actionItems'>
                            <Link to='#' className='actionLinks' onClick={() => openActions('addNotes')}>
                                <img src={addNotes} alt='' className='img-fluid'></img>
                                <span>Add Notes</span>
                            </Link>
                        </li>
                    </ul>
                    </div>
                </div>
            {/* End moreAction Div*/}
            {/* Start Discount Div */}
                <div id='discountAction' className='discountAction'>
                    <div className='moreActionInner'>
                        <header className='actionHeader'>
                            <h6>Add dicount to cart</h6>
                            <button className='closeAction' onClick={() => closeActions('discountAction')}>
                                <i class="las la-times"></i>
                            </button>
                        </header>
                        <div className='discountAreas'>
                            <h5 className='subheading_'>Discount</h5>
                            <ul className='nav nav-pills discountTabs'>
                                <li className='discountTabsItems' id="amount">
                                    <a href="#tab1" data-bs-toggle="tab" role="tab1" class={discountType == 'amount-discount' ? 'discountTabsLinks active' : 'discountTabsLinks'} onClick={() => changeDiscountType('amount-discount')}>
                                        <div className='discountLabelField'>
                                            <input type="radio" id="amount" name="discount" checked/>
                                            <label for="amount" class="labelTxt_">Amount Discount</label>
                                        </div>
                                        <input type='text' placeholder='$ 00.00' value='' className='discountFiled'/>
                                    </a>
                                </li>
                                <li className='discountTabsItems' id="percantage">
                                    <a href="#tab2" data-bs-toggle="tab" role="tab2" 
                                    class={discountType == 'percentage-discount' ? 'discountTabsLinks active' : 'discountTabsLinks'} 
                                    onClick={() => changeDiscountType('percentage-discount')}>
                                        <div className='discountLabelField'>
                                            <input type="radio" id="percentage" name="discount"
                                            checked='checked' />
                                            <label for="percentage" class="labelTxt_">Percentage Discount</label>
                                        </div>
                                        <input type='text' placeholder='$ 00.00' value='1.5 %' className='discountFiled' />
                                    </a>
                                </li>
                                <li className='discountTabsItems'>
                                    <a href="#tab3" data-bs-toggle="tab" role="tab3" class={discountType == 'discount-code' ? 'discountTabsLinks active' : 'discountTabsLinks'} onClick={() => changeDiscountType('discount-code')}>
                                        <div className='discountLabelField'>
                                            <input type="radio" id="discount" name="discount" checked/>
                                            <label for="discount" class="labelTxt_">Discount Code</label>
                                        </div>
                                        <input type='text' placeholder='$ 00.00' value=''className='discountFiled'/>
                                    </a>
                                </li>
                            </ul>
                            <div className='discountTitle mt-3'>
                                <h5 className=''>Discount Title</h5>
                                <input type='text'  placeholder='Title' className='form-control'/>
                            </div>
                        </div>
                    </div>
                </div>
            {/* End Discount Div */}
            {/* Start Add NotesSidebar */}
                <div id='addNotes' className='discountAction'>
                    <div className='moreActionInner'>
                        <header className='actionHeader'>
                            <h6>Add Notes</h6>
                            <button className='closeAction' onClick={() => closeActions('addNotes')}>
                                <i class="las la-times"></i>
                            </button>
                        </header>
                        <div className='discountAreas'>
                            <div className='discountTitle mt-3'>
                                <h5 className=''>Notes</h5>
                                <textarea placeholder='Write notes here' className='form-control'>

                                </textarea>
                                {/* <input type='text'  placeholder='Title' className='form-control'/> */}
                            </div>
                        </div>
                    </div>
                </div>
            {/* End Add NotesSidebar  */}
            {/* Start CheckoutModal */}
                <div id="checkoutSidebar" className='discountAction'>
                    <div className='moreActionInner'>
                        <header className='actionHeader'>
                            <h6>Choose payment option</h6>
                            <button className='closeAction' onClick={() => closeActions('checkoutSidebar')}>
                                <i class="las la-times"></i>
                            </button>
                        </header>
                        <ul className='actionsMenus'>
                            <li className='paymentItems'
                                onClick={() => {
                                setModalDetail({ show: true, flag: 'walletModal', });
                                setKey(Math.random());
                            }}
                            >
                                <Link href='#' className={active == "coin"? "paymentLinks active ": 'paymentLinks'} onClick={() => setActive("coin")}>
                                    <img src={jobrIcon} alt='Coin' />
                                    JBR Coin
                                </Link>
                            </li>
                            <li className='paymentItems'
                                onClick={() => {
                                    setModalDetail({ show: true, flag: 'cashModal', });
                                    setKey(Math.random());
                                }}
                                >
                                <Link href='#' className={active == "cash" ? "paymentLinks active " : 'paymentLinks'} onClick={() => setActive("cash")}>
                                    <img src={cashIcon} alt='Cash' />
                                    Cash
                                </Link>
                            </li>
                            <li className='paymentItems'>
                                <Link href='#' className={active == "card"? "paymentLinks active ": 'paymentLinks'}
                                onClick={() => setActive("card")}>
                                    <img src={cardIcon} alt='Card' />
                                    Card
                                </Link>
                            </li>
                        </ul>
                        <div class="sidebarFooter">
                            <p class="total_price">
                                <strong>Sub Total</strong>
                                <small class="total_amount"><sup>$</sup>4.00</small>
                            </p>
                            <p class="total_price">
                                <small>Sub Total</small>
                                <small class="total_amount"><sup>-$</sup>2.00</small>
                            </p>
                            <p class="total_price">
                                <small>Sub Total</small>
                                <small class="total_amount"><sup>$</sup>4.00</small>
                            </p>
                            <p class="total_price">
                                <strong class="total_amount">Total</strong>
                                <strong class="total_amount"><sup>$</sup>254.60</strong>
                            </p>
                            <p class="total_price">
                                <span class="total_amount">4<small>Items</small></span>
                            </p>
                            
                            <button class="primary_btn mt-4 mb-4" type="button">Checkout <i class="las la-arrow-right ms-2 rightArrow"></i></button>
                        </div>
                    </div>
                </div>
            {/* End CheckoutModal */}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                checkoutModal={true}
                header={
                    <div className='modalHeader_'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common">
                                    <p className="checkout_heading">
                                        {modalDetail.flag === 'updatePriceModal' ?
                                            <>
                                                <span>Update Price</span>
                                            </>
                                            :
                                            modalDetail.flag === "walletModal"
                                            ?
                                            <span>Customer & Payment</span>
                                            :
                                            modalDetail.flag === "cashModal"
                                            ?
                                            <span>Customer</span>
                                            :
                                            <>
                                                <span>Amount: </span>
                                                <span><sup> $</sup>382.75</span>
                                            </>
                                            }
                                    </p>
                                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                        <i class="las la-times" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                child={
                    modalDetail.flag === 'bundleModal' ?
                        <div className="checkout_modal">
                            <div className="item_main">
                                <div className="item_detail">
                                    <img src={checkModal} className="img-fluid item_detailsImg" alt="arrow" />
                                    <span className="">Marlboro Flavor Plus</span>
                                </div>
                                <div className="selected_item">
                                    <select>
                                        <option selected="">Pack</option>
                                        <option>Box</option>
                                        <option>Box</option>
                                        <option>Box</option>
                                        <option>Box</option>
                                    </select>
                                </div>
                            </div>
                            <div className="checkout_price">
                                <p className="total_price">
                                    <span>Price</span>
                                    <span><sup>$</sup>382.75</span>
                                </p>
                            </div>
                            <div className="marlborobox_items">
                                <div className="add_cart">
                                    <button className="digitBtn"><i className="las la-minus"
                                        onclick="incrDecrone(0)"></i></button>
                                    <span className="numberValue" id="noOfvalueone">0</span>
                                    <button className="digitBtn"><i className="las la-plus"
                                        onclick="incrDecrone(1)"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="bundleOffer">
                                <h5 className='bundleHeading'>Bundle offer</h5>
                                <div className="addProduct">
                                    <div className="leftProduct">
                                        <ul>
                                            <li>Buy Pack</li>
                                            <li><strong>2</strong></li>
                                            <li>for</li>
                                        </ul>
                                    </div>
                                    <div className="rightProduct">
                                        <span className="eight"><sup>$</sup>84.99</span>
                                        <Link to="#" className="modalAddBtn">Add</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer footerBtns">
                                <Link to="#" className="remove_cart btn__">Remove from cart</Link>
                                <Link to="#" className="update_cart btn__">Update to cart</Link>
                            </div>
                        </div>
                        : modalDetail.flag === "walletModal" ? <WalletModal/>
                        : modalDetail.flag === "cashModal" ? <CashModal/>
                        : 

                        // Start Update PriceModal
                        <div className='updatePriceModal'>
                            <div className="item_main">
                                <div className="item_detail">
                                    <img src={checkModal} className="img-fluid item_detailsImg" alt="arrow" />
                                    <span className="">Marlboro Flavor Plus</span>
                                </div>
                                <div className="selected_item">
                                    <select>
                                        <option>Pack</option>
                                        <option>Box</option>
                                        <option>Box</option>
                                        <option>Box</option>
                                        <option>Box</option>
                                    </select>
                                </div>
                            </div>
                            <div className='pricesContainer'>
                                <h6 className='pricesHead'>Selling Price</h6>
                                <p className='pricesAmount'><sup>$</sup>0.00</p>
                            </div>
                            <div className="marlborobox_items">
                                <div className="add_cart">
                                    <button className="digitBtn"><i className="las la-minus"></i></button>
                                    <span className="numberValue" id="noOfvalueone">0</span>
                                    <button className="digitBtn"><i className="las la-plus"></i></button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 mb-3'>
                                    <h4 className='trackHeading'>
                                        Track Inventory for this item
                                    </h4>
                                </div>
                                <div className='col-md-6 text-start'>
                                    <p className='inventryHeading'>Inventory-Opening</p>
                                    <input type="text" className='inventoryControl' placeholder='Inventory-Opening' />
                                </div>
                                <div className='col-md-6 text-start'>
                                    <p className='inventryHeading'>Inventory-Reorder Point</p>
                                    <input type="text" className='inventoryControl' placeholder='Inventory-Reorder Point' />
                                </div>
                            </div>
                            <div className="modal-footer footerBtns">
                                <Link to="#" className="remove_cart btn__">Remove from cart</Link>
                                <Link to="#" className="update_cart btn__">Update to cart</Link>
                            </div>
                        </div>
                        // End Update PriceModal
                    }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default RightSidebar