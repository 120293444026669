import { useState } from 'react';
import { Link } from 'react-router-dom';
import { notification } from '../../../utilities/images';
import products from "../../db/category.json"
import a1 from "../../public/images/user.png"
import DataTable from 'react-data-table-component';
import CustomModal from "../../components/shared/CustomModal";
import ProductDetailsModal from './productDetailsModal';

const AnalticsTable = () => {
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const columns1 = [
        {
            name: '#',
            selector: row => row.id,
        },
        {
            name: "Category Name",
            selector: row => {
                return (
                    <>
                        <img style={{ width: 42 }} src={a1} />
                        <span className='contentSubtext_ ms-2'>{row.name}</span>
                    </>
                );
            },
        },
        {
            selector: row => row.price,
            name: 'Sub-Category Listed'
        },
        {
            selector: row => row.brand,
            name: 'Brand listed'
        },
        {
            selector: row => row.listed,
            name: 'Product listed'
        },
        {
            selector: row => row.sold,
            name: 'Total Product Sold'
        },
        {
            selector: row => row.sales,
            name: 'Total Sales'
        }
    ];

    const handleRowClick = (e) => {
        // console.log("rowclicked", e)
        setModalDetail({ show: true, flag: 'productDetailsModal', });
        setKey(Math.random());
    }

    return (
        <>
            <div className='navbarHead'>
                <button class="backBtn_ text-start">
                    <i class="las la-arrow-left me-2 leftArrow"></i>Back
                </button>
                <div className='rightSearch'>
                    <Link to="#" class="midRightlinks">
                        <span class="midRightIcons">
                            <img src={notification} class="img-fluid" />
                        </span>
                    </Link>
                    <form class="searchfom">
                        <input type="text" placeholder="Search.." name="search" />
                        <button type="submit" className='searchbtn'>
                            <i class="fa fa-search"></i>
                        </button>
                    </form>
                    <span class="dotIcon"></span>

                </div>
            </div>
            <div className=''>
                <h3 className='textInnerHead fw-bold'>Category: 4</h3>
            </div>
            <div className='stockTable'>
                    <div className='cateSection'>
                        <select className='cateSelectd' required>
                            <option value="" selected>Category</option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                        <select className='cateSelectd' required>
                            <option value="" selected>Sub Category</option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                        <select className='cateSelectd' required>
                            <option value="" selected>Brand</option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                        <select className='cateSelectd' required>
                            <option value="" selected>Stock Level</option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select>
                        {/* <select className='cateSelectd' required>
                            <option value="" selected>Weekly</option>
                            <option>Category1</option>
                            <option>Category2</option>
                        </select> */}
                    
                </div>
                <DataTable
                    columns={columns1}
                    data={products}
                    pagination
                    defaultSortFieldId={1}
                    onRowClicked={handleRowClick}
                />
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={modalDetail.flag === "productDetailsModal" ? "productDetailsModal" : ""}
                // size={modalDetail.flag === "productDetailsModal" ? "xl" : "md"}
                child={modalDetail.flag === 'productDetailsModal' ? <ProductDetailsModal close={() =>handleOnCloseModal()} />
                    : ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
    
}

export default AnalticsTable