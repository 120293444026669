import React, { useState } from 'react'
import { delBox, scn, Searchlight } from '../../../utilities/images'
import DashboardCategory from './DashboardCategory'
import RightSidebar from './RightSidebar';
import CustomModal from "../../components/shared/CustomModal";
import ScanModal from './ScanModal';
import SearchModal from './SearchModal';
import SingleProductModal from './SingleProductModal';

const DashboardNavbar = () => {
    const [check, setCheck] = useState(false);
    const [key, setKey] = useState(Math.random());
    const openNav2 = () => {
        document.getElementById("mainSidebarOne").setAttribute("style", "width:32%");
        document.getElementById("scrollHide").setAttribute("style", "overflow:hidden")
    }
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const handleModalDetails = (data) => {
        if(data === 'singleProductModal'){
            setModalDetail({ show: true, flag: data }); setKey(Math.random());
        }
    }
    // const collapseHeader = () => {
    //     document.getElementById("itemsDetails").setAttribute("style","height:30%");
    // }
    return (
        <>
            <nav className="mainHeader">
                <span className="openbtn" onclick="openNav()"><i className="fa-solid fa-bars-staggered"></i></span>
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <div className="d-flex align-items-center">
                            <button className="collapseBar" onClick={() => {
                                setCheck(prevCheck => !prevCheck);
                                // collapseHeader()
                            }}>
                                <i className={check === true ? "fa-solid fa-chevron-down collapsArrow" : "fa-solid fa-chevron-down collapsArrow rotate"}>
                                </i>
                                <i className="fa-solid fa-bars-staggered"></i>
                            </button>
                            <div className="input-group search_group">
                                <img src={Searchlight} alt="" className="img-fluid searchIcon " 
                                onClick={() => { setModalDetail({ show: true, flag: 'searchModal', }); setKey(Math.random()); }}
                                />
                                <input type="text" className="form-control" placeholder="Search product here"
                                    aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                <button className="input-group-text" id="basic-addon2" onClick={() => {
                                    setModalDetail({ show: true, flag: 'scanModal', });
                                    setKey(Math.random());
                                }}>
                                    <img src={scn} className="img-fluid scanIcon" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 offset-lg-1">
                        <div className="itemsContainer" onClick={() => openNav2()}>
                            <div>
                                <img src={delBox} className="img-fluid delBox_" />
                                <span className="itemsTxt">Items: <b>4</b></span>
                            </div>
                            <button className="rightBar"><i className="las la-arrow-right"></i></button>
                        </div>
                        <RightSidebar showSidebar={true} />
                    </div>
                </div>
            </nav>
            <DashboardCategory category={check} />

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                header=
                {modalDetail.flag === 'scanModal' ?
                    <div className='modalHeader_'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common">
                                    <p className="checkout_heading">
                                        <span>Add new product</span>
                                    </p>
                                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                        <i class="las la-times" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                : modalDetail.flag == "searchModal" ? 
                    <div className='modalHead_'>
                       <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <p className='productWord'>
                                    <i class="las la-arrow-left me-3 leftArrow"></i> 
                                    <span className='innerSubtext'>Mar</span>
                                </p>
                            </div>
                            <div className='col-md-6 text-end'>
                                <span className='closeAction' onClick={() => handleOnCloseModal()}>
                                    <i class="las la-times"></i>
                                </span>
                            </div>
                       </div>
                    </div>
                : ""
                }
                isRightSideModal={false}
                mediumWidth={false}
                ids={modalDetail.flag === 'scanModal' ? 'scanModal' : 'searchModal'}
                size={modalDetail.flag == "singleProductModal" ? "xl" : "md"}
                child=
                {modalDetail.flag === 'scanModal'
                    ? <ScanModal close={()=>handleOnCloseModal()}/>
                    : modalDetail.flag == "singleProductModal" ? <SingleProductModal close={() =>handleOnCloseModal()}/>
                    : <SearchModal close={() =>handleOnCloseModal()} details={(e) => handleModalDetails(e)}/>   
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default DashboardNavbar