import React from 'react'
import DashboardNavbar from './DashboardNavbar'
import DashboardProduct from './DashboardProduct'
// import { bagImg } from '../../../utilities/images'

const Dashboard = () => {
  return (
      <>
        <DashboardNavbar />
        <DashboardProduct/>
      </>
  )
}

export default Dashboard