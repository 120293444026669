import React from 'react'
import { editIcon, singleProduct } from '../../../utilities/images'

const ProductDetailsModal = (props) => {
  return (
    <div className='productDetailsModal'>
            <div className='singleProductModal text-start'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <button className='backBtn_ text-start' onClick={props.close}>
                        <i class="las la-arrow-left me-2 leftArrow"></i>
                        Back
                        </button>
                    </div>
                    <div className='col-md-6 text-end'>
                        <button className='editBtn_'>
                            <img src={editIcon} class="editIcon" alt="editIcon" />
                            <span>Edit</span>
                        </button>
                        {/* <button className='saveBtn'>
                            <span>Save</span>
                        </button> */}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-4 mb-4'>
                    <h2 className='heading_ fw-bold'>Marlboro Red-Pack</h2>
                    </div>
                    <div className='col-md-5'>
                        <div className='singleProductOuter'>
                            <img src={singleProduct} className='singleProductImg' />
                        </div>
                    </div>
                    <div className='col-md-7'>
                    <div className='outerDiv'>
                        <h3 className='textInnerHead'>Details</h3>
                        <p className='textinner_'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam elementum, ligula quis ultricies iaculis, odio augue venenatis eros, interdum suscipit ipsum turpis nec velit. Vivamus cursus, dui vitae auctor finibus, felis ex malesuada nunc, eget fringilla ante dui in diam. Integer non dapibus nulla.
                        </p>
                    </div>
                    </div>
                </div>
                <div className='row align-items-center bottomProduct mt-3 mb-3'>
                        <div className='col-md-3 custoSpc'>
                            <div className='outerDiv hightlightBorder'>
                                <h3 className='textInnerHead2'>Selling Price</h3>
                                <p className='innerSubhead'>$90</p>
                            </div>
                        </div>
                        <div className='col-md-3 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>Cost Price</h3>
                                <p className='innerSubhead'>$86.50</p>
                            </div>
                        </div>
                        <div className='col-md-3 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>unit Type</h3>
                                <p className='innerSubhead'>Catron</p>
                            </div>
                        </div>
                        <div className='col-md-3 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>Barcode</h3>
                                <p className='innerSubhead'>125694226955</p>
                            </div>
                        </div>
                        <div className='col-md-3 custoSpc'>
                            <div className='outerDiv hightlightBorder'>
                                <h3 className='textInnerHead2'>Re-order</h3>
                                <p className='innerSubhead'>50</p>
                            </div>
                        </div>
                        <div className='col-md-3 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>Unit Weight</h3>
                                <p className='innerSubhead'>430 g</p>
                            </div>
                        </div>
                        <div className='col-md-3 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>Stock on hand </h3>
                                <p className='innerSubhead'>430</p>
                            </div>
                        </div>
                        <div className='col-md-3 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>Rating</h3>
                                <p className='innerSubhead'>4.30</p>
                            </div>
                        </div>
                </div>
            </div>
    </div>
  )
}

export default ProductDetailsModal