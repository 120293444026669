import { Switch, Route } from 'react-router-dom';
import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {

  return (
    <Switch>
      {/* Auth Routes*/}

      {/* login forget password routes */}
      <Layouts.NonPrivateRoute exact path="/" component={Containers.Login} />
      <Layouts.NonPrivateRoute exact path="/verify-phone" component={Containers.VerifyPhone} />
      <Layouts.NonPrivateRoute exact path="/set-passcode" component={Containers.SetPasscode} />
      <Layouts.PrivateRoute exact path="/dashboard" component={Containers.Dashboard} />
      <Layouts.PrivateRoute exact path="/delivery" component={Containers.Delivery} />
      <Layouts.PrivateRoute exact path="/shipping" component={Containers.shipping} />
      <Layouts.PrivateRoute exact path="/shippingOrder" component={Containers.ShippingOrder} />
      <Layouts.PrivateRoute exact path="/selectshipping" component={Containers.Selectshipping} />
      <Layouts.PrivateRoute exact path="/analytics" component={Containers.Analytics} />
      <Layouts.PrivateRoute exact path="/readytoshipping" component={Containers.Readytoshipping} />
      <Layouts.PrivateRoute exact path="/shippingstatus" component={Containers.Shippingstatus} />
      <Layouts.PrivateRoute exact path="/ShippingTrack" component={Containers.ShippingTrack} />
      <Layouts.PrivateRoute exact path="/shippingoutstock" component={Containers.Shippingoutstock} />
      <Layouts.PrivateRoute exact path="/analytics-table" component={Containers.AnalyticsTable} />
      <Layouts.PrivateRoute exact path="/orderDelivery" component={Containers.orderDelivery} />
      <Layouts.PrivateRoute exact path="/Orderprepare" component={Containers.Orderprepare} />
      <Layouts.PrivateRoute exact path="/ReadyPickup" component={Containers.ReadyPickup} />
      <Layouts.PrivateRoute exact path="/customers" component={Containers.customers} />
      <Layouts.PrivateRoute exact path="/wallet" component={Containers.wallet} />
      <Layouts.PrivateRoute exact path="/drawerCash" component={Containers.drawerCash} />
      <Layouts.PrivateRoute exact path="/calender" component={Containers.calender} />
      <Layouts.PrivateRoute exact path="/rewards" component={Containers.rewards} />
      <Layouts.PrivateRoute exact path="/settings" component={Containers.settings} />
      <Layouts.PrivateRoute exact path="/payment-details" component={Containers.PaymentDetails} />
      <Layouts.PrivateRoute exact path="/analyticsStock" component={Containers.AnalyticsStock} />
      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;