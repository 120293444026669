import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { analytics, notification, shareMap } from '../../../utilities/images'

const AnalyticsStock = () => {
    const [show, setShow] = useState("Weekly");
    const history = useHistory()
  return (
    <div className='analyticsStock'>
        <div className='navbarHead'>
            <button class="backBtn_ text-start">
                <i class="las la-arrow-left me-2 leftArrow"></i>Back
            </button>
            <div className='rightSearch'>
                <Link to="#" class="midRightlinks">
                    <span class="midRightIcons">
                        <img src={notification} class="img-fluid" />
                    </span>
                </Link>
                <form class="searchfom">
                    <input type="text" placeholder="Search.." name="search" />
                    <button type="submit" className='searchbtn'>
                        <i class="fa fa-search"></i>
                    </button>
                </form>
                <span class="dotIcon"></span>

            </div>
        </div>
        <div className='stockContainer'>
            <div className='productValues row'>
                <div className='col-md-4'>
                    <h3 className='listHeading fw-bold m-0'>Total Products</h3>
                </div>
                <div className='col-md-8 text-end'>
                    <ul className='valuesTimes m-0'>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Today"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Today")}
                            >Today</Link>
                        </li>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Weekly"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Weekly")}>Weekly</Link>
                        </li>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Monthly"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Monthly")}
                            >Monthly</Link>
                        </li>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Quaterly"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Quaterly")}
                            >Quaterly</Link>
                        </li>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Yearly"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Yearly")}
                            >Yearly</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='productData'>
                <h3 className='headingBoldOuter'>20,590</h3>
                <div className='row mt-3'>
                    <div className='col-lg-6'>
                        <div className='analyticProductDetails'>
                            <div className='row'>
                                <div className='col-md-6 outerBorder'>
                                    <h3 className='textInnerHead'>Product details</h3>
                                    <ul className='productDetailsNumber'>
                                        <li className='productDetailsItems added_'>
                                            <span className='productDetailsValues'>New added</span>
                                            <span className='productDetailsValues fw-bold'>25</span>
                                        </li>
                                        <li className='productDetailsItems discontinued_'>
                                            <span className='productDetailsValues'>Discontinued</span>
                                            <span className='productDetailsValues fw-bold'>95</span>
                                        </li>
                                        <li className='productDetailsItems totalActive_'>
                                            <span className='productDetailsValues'>Total active</span>
                                            <span className='productDetailsValues fw-bold'>311</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-6 text-end'>
                                    <h5 className='contentSubtext_ fw-bold'>Total active Products </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='row percentageArea'>
                            <div className='col-md-6 mb-4'>
                                <div className='percentageContainer' 
                                onClick={()=> {history.push('/analytics-table')}}
                                >
                                    <div className='percentageValues'>
                                        <h3 className='heading_ '>8</h3>
                                        <p className='textInner'>Category</p>
                                    </div>
                                    <div className='percentageRating'>
                                        <img src={shareMap} className="" alt='ShareMap' />
                                        <p className='percentageRate'>1.2%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mb-4'>
                                <div className='percentageContainer'>
                                    <div className='percentageValues'>
                                        <h3 className='heading_ '>70</h3>
                                        <p className='textInner'>Subcategory</p>
                                    </div>
                                    <div className='percentageRating'>
                                        <img src={shareMap} className="" alt='ShareMap' />
                                        <p className='percentageRate'>1.2%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='percentageContainer'>
                                    <div className='percentageValues'>
                                        <h3 className='heading_ '>570</h3>
                                        <p className='textInner'>Brand</p>
                                    </div>
                                    <div className='percentageRating'>
                                        <img src={shareMap} className="" alt='ShareMap' />
                                        <p className='percentageRate'>1.2%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='percentageContainer'>
                                    <div className='percentageValues'>
                                        <h3 className='heading_ '>2,906</h3>
                                        <p className='textInner'>Product</p>
                                    </div>
                                    <div className='percentageRating'>
                                        <img src={shareMap} className="" alt='ShareMap' />
                                        <p className='percentageRate'>1.2%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='stockContainer'>
            <div className='productValues row'>
                <div className='col-md-8 text-start'>
                    <ul className='valuesTimes m-0'>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Today"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Today")}
                            >Today</Link>
                        </li>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Weekly"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Weekly")}>Weekly</Link>
                        </li>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Monthly"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Monthly")}
                            >Monthly</Link>
                        </li>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Quaterly"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Quaterly")}
                            >Quaterly</Link>
                        </li>
                        <li className='valuesTimesItems'>
                            <Link href='#' className={show == "Yearly"? "valuesTimesLinks active":"valuesTimesLinks"}
                            onClick={() => setShow("Yearly")}
                            >Yearly</Link>
                        </li>
                    </ul>
                </div>
                <div className='col-md-4 text-end'>
                    <h3 className='listHeading fw-bold m-0'>Total Inventory Cost</h3>
                </div>
            </div>
            <div className='productData'>
                <h3 className='headingBoldOuter text-end'>$8,426,590</h3>
                <div className='row mt-3'>
                    <div className='col-lg-6'>
                        <div className='row percentageArea'>
                            <div className='col-md-6 mb-4'>
                                <div className='percentageContainer'>
                                    <div className='percentageValues'>
                                        <h3 className='heading_ '>906</h3>
                                        <p className='textInner'>Unit In</p>
                                    </div>
                                    <div className='percentageRating'>
                                        <img src={shareMap} className="" alt='ShareMap' />
                                        <p className='percentageRate'>1.2%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mb-4'>
                                <div className='percentageContainer'>
                                    <div className='percentageValues'>
                                        <h3 className='heading_ '>284</h3>
                                        <p className='textInner'>Unit Out</p>
                                    </div>
                                    <div className='percentageRating'>
                                        <img src={shareMap} className="" alt='ShareMap' />
                                        <p className='percentageRate'>1.2%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='percentageContainer'>
                                    <div className='percentageValues'>
                                        <h3 className='heading_ '>14</h3>
                                        <p className='textInner'>Unit Return</p>
                                    </div>
                                    <div className='percentageRating'>
                                        <img src={shareMap} className="" alt='ShareMap' />
                                        <p className='percentageRate'>1.2%</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='percentageContainer'>
                                    <div className='percentageValues'>
                                        <h3 className='heading_ '>608</h3>
                                        <p className='textInner'>Stock on hand</p>
                                    </div>
                                    <div className='percentageRating'>
                                        <img src={shareMap} className="" alt='ShareMap' />
                                        <p className='percentageRate'>1.2%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='analyticProductDetails'>
                            <div className='row'>
                                <div className='col-md-6 outerBorder'>
                                    <h3 className='textInnerHead'>Product details</h3>
                                    <ul className='productDetailsNumber'>
                                        <li className='productDetailsItems added_'>
                                            <span className='productDetailsValues'>Low Stock items</span>
                                            <span className='productDetailsValues fw-bold'>25</span>
                                        </li>
                                        <li className='productDetailsItems discontinued_'>
                                            <span className='productDetailsValues'>Items to be adjusted</span>
                                            <span className='productDetailsValues fw-bold'>95</span>
                                        </li>
                                        <li className='productDetailsItems totalActive_'>
                                            <span className='productDetailsValues'>Items to be shipped</span>
                                            <span className='productDetailsValues fw-bold'>311</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-6 text-end'>
                                    <h5 className='contentSubtext_ fw-bold'>Total active Products </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AnalyticsStock