import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { checkModal, Robusto, searchProductImg, singleProduct } from '../../../utilities/images'

const SingleProductModal = (props) => {

    return (
      <div className='singleProductModal text-start'>
            <div className='row'>
                <div className='col-md-12'>
                    <button className='backBtn_ text-start' onClick={props.close}>
                    <i class="las la-arrow-left me-2 leftArrow"></i>
                    Back
                    </button>
                </div>
                <div className='col-md-12 mt-4 mb-4'>
                   <h2 className='heading_ fw-bold'>Marlboro Red-Pack</h2>
                </div>
                <div className='col-md-5'>
                    <div className='singleProductOuter'>
                        <img src={singleProduct} className='singleProductImg' />
                    </div>
                </div>
                <div className='col-md-7'>
                    <div class="checkout_price m-0">
                        <p class="total_price"><span>Price</span>
                            <span><sup>$</sup>382.75</span>
                        </p>
                    </div>
                    <div class="marlborobox_items">
                        <div class="add_cart">
                            <button class="digitBtn"><i class="las la-minus"></i></button>
                            <span class="numberValue" id="noOfvalueone">0</span>
                            <button class="digitBtn"><i class="las la-plus"></i></button>
                        </div>
                    </div>
                    <button className='primary_btn'>Add to cart</button>
                </div>
                <div className='row align-items-center bottomProduct mt-3 mb-3'>
                <div className='col-md-5 custoSpc'>
                    <div className='outerDiv'>
                        <h3 className='textInnerHead'>Details</h3>
                        <p className='textinner_'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam elementum, ligula quis ultricies iaculis, odio augue venenatis eros, interdum suscipit ipsum turpis nec velit. Vivamus cursus, dui vitae auctor finibus, felis ex malesuada nunc, eget fringilla ante dui in diam. Integer non dapibus nulla.
                        </p>
                    </div>
                </div>
                <div className='col-md-7'>
                    <div className='row'>
                        <div className='col-md-4 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>unit Type</h3>
                                <p className='innerSubhead'>430</p>
                            </div>
                        </div>
                        <div className='col-md-4 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>unit Type</h3>
                                <p className='innerSubhead'>430</p>
                            </div>
                        </div>
                        <div className='col-md-4 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>unit Type</h3>
                                <p className='innerSubhead'>430</p>
                            </div>
                        </div>
                        <div className='col-md-4 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>unit Type</h3>
                                <p className='innerSubhead'>430</p>
                            </div>
                        </div>
                        <div className='col-md-4 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>unit Type</h3>
                                <p className='innerSubhead'>430</p>
                            </div>
                        </div>
                        <div className='col-md-4 custoSpc'>
                            <div className='outerDiv'>
                                <h3 className='textInnerHead2'>unit Type</h3>
                                <p className='innerSubhead'>430</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
      </div>
    )
}

export default SingleProductModal

