import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import NavbarCommonBack from "../dashboard/NavbarcommonBack";
import {
  userimg,
  exdelivery,
  mailingship,
  mailingship1,
  mailingship2,
  mailingship3
} from "../../../utilities/images";

const SelectShipping = () => {
  const [radioValue, setRadioValue] = useState("1");
  return (
    <>
      <NavbarCommonBack />
      <main>
        <div className="MainContentpage">
          <div className="row">
            <div className="col-md-6 ">
              <div className="orderExpress selectexpress">
                <div className="ReviewHead">
                  <h3>Order#WXCOO1256</h3>
                  <span>August 30, 2022</span>
                </div>
                <div className="orderDetails_">
                  <div className="userDetail">
                    <img src={userimg} />
                    <div className="userContent">
                      <h3>Rebecca R. Russell</h3>
                      <h6>1480 Bassel Street, New Orleans, LA 70113</h6>
                    </div>
                  </div>
                  <div className="userDetail">
                    <img src={exdelivery} />
                    <div className="userContent">
                      <h3 class="blueText_">Express Delivery</h3>
                      <h6>Immediately</h6>
                    </div>
                  </div>
                </div>
                <div className="listOrders">
                  <table className="table table-responsive listItemsContainer">
                    <tbody>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>JFR Maduro</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>382.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Ashton Magnum</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>236.50
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Marlboro Touch</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>43.99
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="expressOrderFooter sidebarFooter">
                  <div className="row justify-content-end">
                    <div className="priceBox">
                      <p class="total_price">
                        <strong>Sub Total</strong>
                        <small class="total_amount">
                          <sup>$</sup>4.00
                        </small>
                      </p>
                      <p class="total_price">
                        <small>Sub Total</small>
                        <small class="total_amount">
                          <sup>-$</sup>2.00
                        </small>
                      </p>
                      <p class="total_price">
                        <small>Sub Total</small>
                        <small class="total_amount">
                          <sup>$</sup>4.00
                        </small>
                      </p>
                      <p class="total_price">
                        <strong class="total_amount">Total</strong>
                        <strong class="total_amount">
                          <sup>$</sup>254.60
                        </strong>
                      </p>
                      <p class="total_price">
                        <span class="total_amount">
                          4<small>Items</small>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="selectShippbox">
                <div class="ReviewHead">
                  <h3>Select shipping</h3>
                </div>
                <form className="horizontalship">
                  <div className="Mainprority">
                    <label className="checkbox_">
                      <div className="priorityLeft">
                        <img src={mailingship} />
                        <article className="rightshipcontent">
                          <h3>Priority Mailing services</h3>
                          <h6>1-3 days shipping</h6>
                        </article>
                      </div>
                      <div className="priorityR">
                        <h5>$11.50</h5>
                      </div>
                      <input
                        type="radio"
                        className="check_main d-none"
                        id="priority1"
                        name="optionOne"
                        value="Yes"
                      
                      />
                      <span className="checkmarks"></span>
                    </label>
                  </div>
                  <div className="Mainprority">
                    <label className="checkbox_">
                      <div className="priorityLeft">
                        <img src={mailingship1} />
                        <article className="rightshipcontent">
                          <h3>Priority Mailing services</h3>
                          <h6>1-3 days shipping</h6>
                        </article>
                      </div>
                      <div className="priorityR">
                        <h5>$11.50</h5>
                      </div>
                      <input
                        type="radio"
                        className="check_main d-none"
                        id="priority2"
                        name="optionOne"
                        value="No"
                      />
                      <span className="checkmarks"></span>
                    </label>
                  </div>
                  <div className="Mainprority">
                    <label className="checkbox_">
                      <div className="priorityLeft">
                        <img src={mailingship2} />
                        <article className="rightshipcontent">
                          <h3>Priority Mailing services</h3>
                          <h6>1-3 days shipping</h6>
                        </article>
                      </div>
                      <div className="priorityR">
                        <h5>$11.50</h5>
                      </div>
                      <input
                        type="radio"
                        className="check_main d-none"
                        id="priority3"
                        name="optionOne"
                        value="Yes"
                       
                      />
                      <span className="checkmarks"></span>
                    </label>
                  </div>
                  <div className="Mainprority">
                    <label className="checkbox_">
                      <div className="priorityLeft">
                        <img src={mailingship3} />
                        <article className="rightshipcontent">
                          <h3>Priority Mailing services</h3>
                          <h6>1-3 days shipping</h6>
                        </article>
                      </div>
                      <div className="priorityR">
                        <h5>$11.50</h5>
                      </div>
                      <input
                        type="radio"
                        className="check_main d-none"
                        id="priority1"
                        name="optionOne"
                        value="No"
                      />
                      <span className="checkmarks"></span>
                    </label>
                  </div>
                </form>
                <div className="printbtn">
                  <button type="submit" className="printbtn">
                    Select & Continue to print
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SelectShipping;
