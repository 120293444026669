import React from 'react'
import { Chart } from "react-google-charts";
import { Link, useHistory } from 'react-router-dom';
import { analytics, deliveryOrder, notification } from '../../../utilities/images';

const AnalticsDashboard = () => {
    const history = useHistory()
    const totalOrderData = [
        ["Week", "Sales"],
        ["Monday", 10],
        ["Tuesday", 70],
        ["Wednesday", 60],
        ["Thursday", 90],
        ["Friday", 30],
        ["Saturday", 50],
        ["Sunday", 40],
    ];

    const totalInventoryData = [
        ["x", "", ""],
        [0, 0, 0],
        [1, 10, 5],
        [2, 23, 15],
        [3, 17, 9],
        [4, 18, 10],
        [5, 9, 5],
        [6, 11, 3],
        [7, 27, 19],
    ];

    const totalInventoryOptions = {
        isStacked: true,
        // height: 300,
        // width: 700,
        legend: { position: "top", maxLines: 3 },
        vAxis: { minValue: 0 },
        colors: ["#275AFF", "#1FB3FF"]
    };

    const totalOrderOptions = {
        isStacked: true,
        // height: 300,
        // width: 700,
        legend: { position: "top", maxLines: 3 },
        vAxis: { minValue: 0 },
    };

    const totalRevenueData = [
        ["Week", "Store", "Delivery", "Shipping"],
        ["Monday", 1000, 400, 200],
        ["Tuesday", 1170, 460, 250],
        ["Wednesday", 1030, 540, 350],
        ["Thursday", 1030, 40, 350],
        ["Friday", 1030, 590, 350],
        ["Saturday", 1030, 590, 350],
        ["Sunday", 1030, 540, 450]
    ];

    const totalRevenueOptions = {
        chart: {
            // title: "Company Performance",
            // subtitle: "Total Revenue"
        },
        colors: ['#275AFF', '#1FB3FF', '#1CD3FF']
    };
    return (
        <>
        <div className='navbarHead'>
            <div className='Mainheadingnav'>
                <img src={analytics} className="img-fluid showImg" alt="" />
                <h3>Anasslytics</h3>
            </div>
            <div className='rightSearch'>
                <Link to="#" class="midRightlinks">
                    <span class="midRightIcons">
                        <img src={notification} class="img-fluid" />
                    </span>
                </Link>
                <form class="searchfom">
                    <input type="text" placeholder="Search.." name="search" />
                    <button type="submit" className='searchbtn'>
                        <i class="fa fa-search"></i>
                    </button>
                </form>
                <span class="dotIcon"></span>

            </div>
        </div>
        <div className='row'>
            <div className='col-md-6 mb-4'>
                <div className='chartsOuter'>
                    <div className='chartsDetails'>
                        <div className='chartsValues'>
                            <p className='subheading2_'>Total Products </p>
                            <p className='headingBold'>20,560  </p>
                        </div>
                        <span className='rightArrow'
                        onClick={()=>{history.push('/analyticsStock')}}>
                            <i class="las la-angle-right"></i>
                        </span>
                    </div>
                    <div className='chatsArea'>
                        <Chart
                            chartType="AreaChart"
                            width="100%"
                            height="400px"
                            data={totalOrderData}
                            options={totalOrderOptions}
                        />
                    </div>
                </div>
            </div>
            <div className='col-md-6 mb-4'>
                <div className='chartsOuter'>
                    <div className='chartsDetails'>
                        <div className='chartsValues'>
                            <p className='subheading2_'>Total Revenue </p>
                            <p className='headingBold'>$6,920,590</p>
                        </div>
                        <span className='rightArrow'><i class="las la-angle-right"></i></span>
                    </div>       
                    <div className='chatsArea'>
                        <Chart
                            chartType="Bar"
                            width="80%"
                            height="300px"
                            data={totalRevenueData}
                            options={totalRevenueOptions}
                        />
                    </div>
                </div>
            </div>
            <div className='col-md-6 mb-4'>
                <div className='chartsOuter'>
                    <div className='chartsDetails'>
                        <div className='chartsValues'>
                            <p className='subheading2_'>Total Inventory Cost </p>
                            <p className='headingBold'>$8,426,590</p>
                        </div>
                        <span className='rightArrow'><i class="las la-angle-right"></i></span>
                    </div>
                    <div className='chatsArea'>
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="400px"
                            data={totalInventoryData}
                            options={totalInventoryOptions}
                        />
                    </div>
                </div>
            </div>
            <div className='col-md-6 mb-4'>
                <div className='chartsOuter'>
                    <div className='chartsDetails'>
                        <div className='chartsValues'>
                            <p className='subheading2_'>Total Order </p>
                            <p className='headingBold'> $8,426,590</p>
                        </div>
                        <span className='rightArrow'><i class="las la-angle-right"></i></span>
                    </div> 
                    <div className='chatsArea'>
                        <Chart
                            chartType="AreaChart"
                            width="100%"
                            height="400px"
                            data={totalOrderData}
                            options={totalOrderOptions}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AnalticsDashboard