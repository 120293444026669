import { current } from '@reduxjs/toolkit'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { checkModal, Robusto, searchProductImg } from '../../../utilities/images'

const SearchModal = (props) => {    
    const [show, setShow] = useState({
        id: '',
        status: false
    });
    const handleClick = (id, status) => {
        setShow({
            id: id,
            status: status
        });
    };
    const data = [1,2,3,4,5]
    return (
        <div className='searchProductContainer'>
            {data.length > 0 &&  data.map( (item, index) => {
                return(
                    <div className='containerdetails' key={index}>
                        <div className='searchProductArea'>
                            <div className='searchProductDeatils'>
                                <img src={searchProductImg} className='productImg' />
                                <div className='searchProductDeatils__ text-start'>
                                    <h3 className='titleTxt'>Marlboro Red-Pack</h3>
                                    <p className='contentSubtext_'>206 in stock</p>
                                    <p className='marlboro_subheading'><i>Available in another location</i></p>
                                </div>
                            </div>
                            <div className='productPriceDetails text-end'>
                                <h3 className='titleTxt'>$7.59</h3>
                                {show.id == item && show.status ?
                                    <Link href='#' className='viewDetails'
                                        onClick={() => handleClick(item, false)}
                                    >
                                        Hide Details</Link>
                                    :
                                    <Link href='#' className='viewDetails'
                                        onClick={() => handleClick(item, true)}
                                    >
                                        View Details</Link>
                                }
                            </div>
                        </div>
                    {/* View details */}
                        <div id='viewDetails' className={show.id == item && show.status ? "active" : ""} >
                            <p className='availableTxt'>Available in stock: 206</p>
                            <div class="item_main">
                                <div class="item_detail">
                                    <img src="/static/media/checkModal.ee6db6db12ff3d32d373367ad88217d5.svg" class="img-fluid item_detailsImg" alt="arrow" />
                                    <span class="">Marlboro Flavor Plus</span>
                                </div>
                                <div class="selected_item">
                                    <select>
                                        <option>Pack</option>
                                        <option>Box</option>
                                        <option>Box</option>
                                        <option>Box</option>
                                        <option>Box</option>
                                    </select>
                                </div>
                            </div>
                            <div class="checkout_price">
                                <p class="total_price">
                                    <span>Price</span>
                                    <span><sup>$</sup>382.75</span>
                                </p>
                            </div>
                            <div class="marlborobox_items border-0">
                                <div class="add_cart">
                                    <button class="digitBtn"><i class="las la-minus"></i></button>
                                    <span class="numberValue" id="noOfvalueone">0</span>
                                    <button class="digitBtn"><i class="las la-plus"></i></button>
                                </div>
                            </div>
                            <div class="bundleOffer">
                                <h5 class="bundleHeading">Bundle offer</h5>
                                <div class="addProduct">
                                    <div class="leftProduct">
                                        <ul>
                                            <li>Buy Pack</li>
                                            <li><strong>2</strong></li>
                                            <li>for</li>
                                        </ul>
                                    </div>
                                    <div class="rightProduct">
                                        <span class="eight"><sup>$</sup>84.99</span>
                                        <a class="modalAddBtn" href="/dashboard">Add</a>
                                    </div>
                                </div>
                                <div class="addProduct">
                                    <div class="leftProduct">
                                        <ul>
                                            <li>Buy Pack</li>
                                            <li><strong>2</strong></li>
                                            <li>for</li>
                                        </ul>
                                    </div>
                                    <div class="rightProduct">
                                        <span class="eight"><sup>$</sup>84.99</span>
                                        <a class="modalAddBtn" href="/dashboard">Add</a>
                                    </div>
                                </div>
                            </div>
                            <button class="primaryBtn mt-5" type="button"
                                onClick={() => { props.details('singleProductModal') }}
                            >Add to Cart</button>
                        </div>
                    </div>
                )}
            )}
        </div>
    )
}

export default SearchModal