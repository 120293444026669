import React from 'react'
import { barcode } from '../../../utilities/images'

const ScanModal = (props) => {
  return (
    <div className='barCodeModal'>
        <div className='barcodeHead'>
            <h6 className='trackHeading mb-4'>Barcode</h6>
            <img src={barcode} className='img-fluid barCodeImg' />
            <div className='form-group col-lg-12 mt-3 mb-4'>
                <label className='labelText_' for='barCodenumber'>Scanned Barcode</label>
                <input className='form-control' id='barCodenumber' type='text' placeholder='Barcode Number' value='0123-4567' name=''/>
            </div>
        </div>  
        <form className='customForm'>
            <div className='form-group col-lg-12 mt-3 mb-3'>
                <label className='labelText_' for='barCodenumber'>Product Name</label>
                <input className='form-control' id='barCodenumber' type='text' placeholder='Product name' value='0123-4567' name=''/>
            </div>
            <div className='form-group col-lg-12 mt-3 mb-3'>
            <label className='labelText_' for='barCodenumber'>Select Category</label>
                <select class="form-select" aria-label="Default select example" placeholder=''>
                    <option selected>Select Category</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div className='form-group col-lg-12 mt-3 mb-3'>
                <label className='labelText_' for='barCodenumber'>Select Sub-category</label>
                <select class="form-select" aria-label="Default select example" placeholder=''>
                    <option selected>Select Category</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div className='form-group col-lg-12 mt-3 mb-3'>
                <label className='labelText_' for='barCodenumber'>Select Brand</label>
                <select class="form-select" aria-label="Default select example" placeholder=''>
                    <option selected>Select Category</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
        </form>
        <div class="pricesContainer">
            <h6 class="pricesHead">Selling Price</h6>
            <p class="pricesAmount"><sup>$</sup>0.00</p>
        </div>
        <div class="marlborobox_items mt-4">
            <div class="add_cart">
                <button class="digitBtn">
                    <i class="las la-minus"></i>
                </button>
                <span class="numberValue" id="noOfvalueone">0</span>
                <button class="digitBtn">
                    <i class="las la-plus"></i>
                </button>
            </div>
        </div>
        <div class="modal-footer customSpc">
            <a class="remove_cart btn__" href="/dashboard">Remove from cart</a>
            <a class="update_cart btn__" href="/dashboard">Update to cart</a>
        </div>
    </div>
  )
}

export default ScanModal