import React from 'react'
import { Link } from 'react-router-dom'
import { categoryFive, categoryFour, categoryOne, categoryThree, categoryTwo } from '../../../utilities/images'

const DashboardCategory = (props) => {


    return (
        <div className={props.category == false ? "collapse" : ""} id="itemsDetails">
            <div className="card card-body">
                <ul className="card_category">
                    <h6 className="category_heading">Category</h6>
                    <li className="category_box active">
                        <Link to="#">
                            <img src={categoryOne} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Tobacco</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryTwo} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Vape</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryThree} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Cigar</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryFour} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Cigarettes</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="card card-body">
                <ul className="card_category">
                    <h6 className="category_heading">Sub-Category</h6>
                    <li className="category_box active">
                        <Link to="#">
                            <img src={categoryFive} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Cigarettes</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryTwo} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Cigarillos</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryThree} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Rolling Papers</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryFour} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Tobacco</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryFour} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Tobacco</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="card card-body">
                <ul className="card_category">
                    <h6 className="category_heading">Brand</h6>
                    <li className="category_box active">
                        <Link to="#">
                            <img src={categoryFive} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Marlboro</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryFive} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">L&M</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryFive} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Pall Mall</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryFive} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Copenhagen</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryFive} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">CAMEL</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryFive} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Winston</span>
                        </Link>
                    </li>
                    <li className="category_box">
                        <Link to="#">
                            <img src={categoryFive} alt="icon" className="img-fluid category_icon" />
                            <span className="category_type">Parliament</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default DashboardCategory