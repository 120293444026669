import React from 'react'
import { Link } from 'react-router-dom'
import NavbarCommon from '../dashboard/NavbarCommon'
import { orderreview, prepare, driver, locdelivery, money, watch, rightarrow, deliveryparcel, deliverytruck } from '../../../utilities/images'
import Chart from 'react-google-charts'

const delivery = () => {
  const totalOrderData = [
    ["Week", "Sales"],
    ["Monday", 10],
    ["Tuesday", 70],
    ["Wednesday", 60],
    ["Thursday", 90],
    ["Friday", 30],
    ["Saturday", 50],
    ["Sunday", 40],
  ];
  const totalOrderOptions = {
    isStacked: true,
    height: 300,
    width: 700,
    legend: { position: "top", maxLines: 3 },
    vAxis: { minValue: 0 }
  };

  const orderConvertionData= [
    ["Task", "Hours per Day"],
    ["Work", 10],
    ["null", 2]
  ]
  const orderConvertionOption = {
    colors: ["#275AFF", "#EFEFEF"]
  }
  return (
    <>
      <NavbarCommon />
      <main>
        <div className='DeliveryOrders'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='InnerOrders'>
                <img src={orderreview} />
                <div className='orderInfo'>
                  <h3 className='deliveryHead'>49</h3>
                  <h5 className='deliverysub'>Orders to Review</h5>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='InnerOrders'>
                <img src={prepare} />
                <div className='orderInfo'>
                  <h3 className='deliveryHead'>23</h3>
                  <h5 className='deliverysub'>Order Preparing</h5>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='InnerOrders'>
                <img src={prepare} />
                <div className='orderInfo'>
                  <h3 className='deliveryHead'>15</h3>
                  <h5 className='deliverysub'>Ready to pickup</h5>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='InnerOrders'>
                <img src={driver} />
                <div className='orderInfo'>
                  <h3 className='deliveryHead'>9</h3>
                  <h5 className='deliverysub'>Delivering</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='MainContent'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='ordersNumbers'>
                <h3>Number of Orders</h3>
                <div>
                  <Chart
                    chartType="AreaChart"
                    width="100%"
                    height="400px"
                    data={totalOrderData}
                    options={totalOrderOptions}
                  />
                </div>
              </div>
              <div className='ordersConverstion'>
                <h3>Order convertion</h3>
                <div className='row'>
                  <div className='col-md-6'>
                  <Chart
                      chartType="PieChart"
                      data={orderConvertionData}
                      options={orderConvertionOption}
                      width={"100%"}
                      height={"400px"}
                    />
                  </div>
                  <div className='col-md-6'>
                    <div className='converstionLeft'>
                      <div className='innerconverstion'>
                        <h3 className='deliveryHead'>49</h3>
                        <h5 className='deliverysub'>Orders Placed</h5>
                      </div>
                      <div className='innerconverstion'>
                        <h3 className='deliveryHead'>3</h3>
                        <h5 className='deliverysub'>Orders Cancelled</h5>
                      </div>
                      <div className='innerconverstion'>
                        <h3 className='deliveryHead'>46</h3>
                        <h5 className='deliverysub'>Orders delivered</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='orderReview'>
                <div className='ReviewHead'>
                  <h3>Orders to review</h3>
                  <Link to="/orderDelivery" className="viewbtn">View All</Link>
                </div>
                <div className='reviewContent'>
                  <div className='viewUser'>
                    <h5>Rebecca R. Russell</h5>
                    <p className='distanceMiles'>
                      <img src={locdelivery} />
                      <span>2.5 miles</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5>3 items</h5>
                    <p className='distanceMiles'>
                      <img src={money} />
                      <span>$489.50</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5 className='bluehead'>Expess delivery</h5>
                    <p className='distanceMiles'>
                      <img src={watch} />
                      <span>Expess delivery</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='reviewContent'>
                  <div className='viewUser'>
                    <h5>Rebecca R. Russell</h5>
                    <p className='distanceMiles'>
                      <img src={locdelivery} />
                      <span>2.5 miles</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5>3 items</h5>
                    <p className='distanceMiles'>
                      <img src={money} />
                      <span>$489.50</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5 className='bluehead'>Expess delivery</h5>
                    <p className='distanceMiles'>
                      <img src={watch} />
                      <span>Expess delivery</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='reviewContent'>
                  <div className='viewUser'>
                    <h5>Rebecca R. Russell</h5>
                    <p className='distanceMiles'>
                      <img src={locdelivery} />
                      <span>2.5 miles</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5>3 items</h5>
                    <p className='distanceMiles'>
                      <img src={money} />
                      <span>$489.50</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5 className='bluehead'>Expess delivery</h5>
                    <p className='distanceMiles'>
                      <img src={watch} />
                      <span>Expess delivery</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='reviewContent'>
                  <div className='viewUser'>
                    <h5>Rebecca R. Russell</h5>
                    <p className='distanceMiles'>
                      <img src={locdelivery} />
                      <span>2.5 miles</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5>3 items</h5>
                    <p className='distanceMiles'>
                      <img src={money} />
                      <span>$489.50</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5 className='bluehead'>Expess delivery</h5>
                    <p className='distanceMiles'>
                      <img src={watch} />
                      <span>Expess delivery</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='reviewContent'>
                  <div className='viewUser'>
                    <h5>Rebecca R. Russell</h5>
                    <p className='distanceMiles'>
                      <img src={locdelivery} />
                      <span>2.5 miles</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5>3 items</h5>
                    <p className='distanceMiles'>
                      <img src={money} />
                      <span>$489.50</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5 className='bluehead'>Expess delivery</h5>
                    <p className='distanceMiles'>
                      <img src={watch} />
                      <span>Expess delivery</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='reviewContent'>
                  <div className='viewUser'>
                    <h5>Rebecca R. Russell</h5>
                    <p className='distanceMiles'>
                      <img src={locdelivery} />
                      <span>2.5 miles</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5>3 items</h5>
                    <p className='distanceMiles'>
                      <img src={money} />
                      <span>$489.50</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5 className='bluehead'>Expess delivery</h5>
                    <p className='distanceMiles'>
                      <img src={watch} />
                      <span>Expess delivery</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='reviewContent'>
                  <div className='viewUser'>
                    <h5>Rebecca R. Russell</h5>
                    <p className='distanceMiles'>
                      <img src={locdelivery} />
                      <span>2.5 miles</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5>3 items</h5>
                    <p className='distanceMiles'>
                      <img src={money} />
                      <span>$489.50</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5 className='bluehead'>Expess delivery</h5>
                    <p className='distanceMiles'>
                      <img src={watch} />
                      <span>Expess delivery</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='reviewContent'>
                  <div className='viewUser'>
                    <h5>Rebecca R. Russell</h5>
                    <p className='distanceMiles'>
                      <img src={locdelivery} />
                      <span>2.5 miles</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5>3 items</h5>
                    <p className='distanceMiles'>
                      <img src={money} />
                      <span>$489.50</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <h5 className='bluehead'>Expess delivery</h5>
                    <p className='distanceMiles'>
                      <img src={watch} />
                      <span>Expess delivery</span>
                    </p>
                  </div>
                  <div className='viewUser'>
                    <img src={rightarrow} />
                  </div>
                </div>
              </div>
              <div className='deliveringOrders'>
                <div className='ReviewHead'>
                  <h3>Delivering Orders</h3>
                </div>
                <div className='mainExpress'>
                  <div className='expressDelivery'>
                    <div className='deliveryTop'>
                    <p>
                      <img src={deliveryparcel} />
                      <span>Expess delivery</span>
                      </p>
                      <img src={rightarrow} />
                    </div>
                    <h4>3</h4>
                  </div>
                  <div className='expressDelivery'>
                    <div className='deliveryTop'>
                    <p>
                      <img src={deliverytruck} />
                      <span>1 hour delivery window</span>
                      </p>
                      <img src={rightarrow} />
                    </div>
                    <h4>3</h4>
                  </div>
                  <div className='expressDelivery'>
                    <div className='deliveryTop'>
                      <p>
                      <img src={deliverytruck} />
                      <span>2 hour delivery window</span>
                      </p>
                      <img src={rightarrow} />
                    </div>
                    <h4>3</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default delivery